// ----------------------------------------------------------------------

import { hr } from 'date-fns/locale';

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: 'English',
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: 'Hi',
    description: 'Need help? \n Please check our docs.',
    documentation: 'documentation',
  },
  comingSoon: {
    title: 'MDP: Coming Soon',
    comingSoon: 'Coming Soon!',
    description: 'We are currently working hard on this page!',
  },
  404: {
    title: 'MDP: 404 - Page not found',
    pageNotFound: 'Page not found',
    description:
      "Sorry, we couldn't find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.",
  },
  403: {
    title: 'MDP: 403 - Forbidden',
    noPermission: 'No Permission',
    description: "The page you're trying access has restricted access.",
  },
  500: {
    title: 'MDP: 500 - Internal Server Error',
    ise: 'Internal Server Error',
    description: 'There was an error, please try again later.',
  },
  dashboard: {
    title: 'MDP: Application',
    lastRefresh: 'Last Refresh',
    monthlyUsers: 'Monthly Users',
    monthlyNewUsers: 'Monthly New Users',
    monthlyRevenue: 'Monthly Revenues',
    monthlyOrders: 'Monthly Orders',
    todayActiveUsers: 'Today: Active Users',
    todayNewUsers: 'Today: New Users',
    todayTotalRevenue: 'Today: Total Revenue',
    last3MonthsTopCountries: 'Last 3 Months: Top 10 Countries',
    websiteKpis: 'Website KPIs',
    last3months: 'Last 3 Months',
    totalUsers: 'Total Users',
    newUsers: 'New Users',
    latestOrders: 'Latest Orders',
    trafficBySite: 'Traffic by Site',
    trafficByDevice: 'Traffic by Device',
  },
  header: {
    placeholder: 'Search barcode',
    searchButton: 'Search',
    error: "Invalid barcode"
  },
  devices: {
    mobile: 'Mobile',
    desktop: 'Desktop',
    tablet: 'Tablet',
    other: 'Other',
  },
  sites: {
    google: 'Google',
    direct: 'Direct',
    facebook: 'Facebook',
    other: 'Other',
  },
  statuses: {
    unfulfilled: 'Unfulfilled',
    refunded: 'Refunded',
    fulfilled: 'Fulfilled',
    pending: 'Pending',
    cancelled: 'Cancelled',
    draft: 'Draft',
    completed: 'Completed',
    partial: 'Partially',
    restocked: 'Cancelled',
  },
  delayedPaymentDialog: {
    title: 'Delayed Payment',
    paymentTerm: 'Payment Term',
    receipt: 'Payment due upon invoice receipt',
    net: 'Paiement under',
    days: 'days',
    fulfillment: 'Payment due upon fulfillment',
    fixed: 'Payment on',
    issued_at: 'Issued at',
    due_at: 'Due at',
  },
  discountDialog: {
    title: 'Add Discount',
    discountType: 'Discount Type',
    value: 'Value',
    description: 'Description',
    types: {
      fixed_amount: 'Fixed Amount',
      percentage: 'Percentage',
    },
    requirements: {
      discountType: 'Discount type is required',
      value: 'Value is required',
    },
  },
  shippingDialog: {
    title: 'Add Shipping',
    shippingType: 'Shipping Type',
    price: 'Price',
    types: {
      fixed_rate: 'Fixed Rate',
      collection: 'Collection',
      custom: 'Custom',
    },
  },
  customerListDialog: {
    select: 'Select Customer',
    addNew: 'New Customer',
  },
  customProductDialog: {
    title: 'Add a custom product',
    requirements: {
      title: 'A title is required',
      price: 'A price is required',
      quantity: 'A quantity is required',
    },
    fields: {
      title: 'Title',
      price: 'Price',
      quantity: 'Quantity',
      taxable: 'Taxable',
      grams: 'Grams',
    },
  },
  ordersPage: {
    navTitle: 'Orders',
    title: 'MDP: Orders List',
    heading: 'Orders List',
    dashboard: 'Dashboard',
    searchEmail: 'Search client name or email',
    clear: 'Delete search',
    search: 'Search',
    newOrder: 'New order',
    ordersHeading: {
      total: 'Total',
      fulfilled: 'Fulfilled',
      other: 'Others',
      draft: 'Draft',
    },
  },
  productListDialog: {
    title: 'Select Product',
    lookingFor: 'Looking for',
    pleaseWait: 'Please wait...',
    variantTitle: 'Variant Title',
    barCode: 'Bar Code',
  },
  deleteDialog: {
    title: 'Confirm deletion',
    dialog: 'You are about to delete this order. This action cannot be undone.',
  },
  orderCreatePage: {
    title: 'MDP: Create Order',
    headingCreate: 'Create Order',
    headingEdit: 'Edit Order',
    newOrder: 'New Order',
    productHeading: 'Products',
    addCustomProduct: 'Add Custom Product',
    editDiscount: 'Edit Discount',
    addDiscount: 'Add Discount',
    editShipping: 'Edit Shipping',
    addShipping: 'Add Shipping',
    delete: 'Delete',
    shipping: 'Shipping',
    taxes: 'Taxes',
    totalPrice: 'Total Price',
    sendInvoice: 'Send Invoice',
    searchProduct: 'Search Product',
    searchProductPlaceholder: 'Search product name or bar code',
    update: 'Update',
    completeOrder: 'Complete Order',
    markAsPaid: 'Mark as Paid',
    cancel: 'Cancel Order',
    createOrder: 'Create Order',
    details: 'Details: ',
    subTotal: 'Subtotal',
    fields: {
      tags: 'Tags',
    },
    paymentTerms: {
      net: 'Payment under',
      days: 'days',
      receipt: 'Payment due upon invoice receipt',
      fulfillment: 'Payment due upon fulfillment',
      payment_on: 'Payment on',
      issued_at: 'Issued at:',
      due_at: 'Due at:',
    },
    reservedInventory: {
      title: 'Reserve Inventory',
      reservedInventoryUntil: 'Reserved inventory until',
    },
  },
  orderDetailsPage: {
    title: 'MDP: Order',
    subtotal: 'Subtotal',
    customerInfo: 'Customer Info',
    shipBy: 'Ship By',
    delivery: 'Delivery',
  },
  companyListPage: {
    navTitle: 'Companies',
    title: 'MDP: companies',
    heading: 'List of companies',
    newCompany: 'New company',
    currentlyEditing: 'Edit in progress',
  },
  apeLinkPage: {
    breadcrumbs: 'APE Link',
    navTitle: 'APE Link',
    title: 'MDP: APE Link',
    heading: 'APE code link',
    addButton: 'Add APE code',
    addModalTitle: 'Add APE code',
    linkModalTitle: 'Link publications',
    confirm: 'Confirm',
    edit: 'Edit',
    add: 'Add',
    cancel: 'Cancel',
    save: 'Save',
    deleteMessage: 'Are you sure you want to do away with this link?',
    fields: {
      apeCode: 'APE Code',
      publications: 'Publications',
    },
  },
  locationLinkPage: {
    breadcrumbs: 'Location Link',
    navTitle: 'Location Link',
    heading: 'Location Link',
    title: 'MDP: Location Link',
    addButton: 'Add Location',
    addModalTitle: 'Add Location Type',
    linkModalTitle: 'Link Location',
    confirm: 'Confirm',
    edit: 'Edit',
    add: 'Add',
    cancel: 'Cancel',
    save: 'Save',
    deleteMessage: 'Are you sure you want to delete this link?',
    fields: {
      locationsType: 'Location Types',
      locations: 'Locations',
    },
    updateSuccess: 'Location links have been successfully updated',
  },
  publicationsLinkPage: {
    breadcrumbs: 'Publications Link',
    navTitle: 'Publications Link',
    title: 'MDP: Publications Link',
    heading: 'Publications Link',
    linkModalTitle: 'Link publications',
    confirm: 'Confirm',
    edit: 'Edit',
    add: 'Add',
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    deleteMessage: 'Are you sure you want to do away with this link?',
    fields: {
      publicationsType: 'Publications Type',
      publications: 'Publications',
    },
  },
  apeCodes: 'APE code',
  userListPage: {
    navTitle: 'Users',
    title: 'MDP: Users List',
    heading: 'Users List',
    users: 'Users',
    newUser: 'New User',
    sureDelete: 'Are you sure you want to delete',
    sureDelete2: 'users?',
    name: 'Name',
    role: 'Role',
  },
  searchBar: {
    search: 'Search...',
    searchButton: 'Search',
    clear: 'Clear',
  },
  stockListPage: {
    title: 'MDP: Stock List',
    heading: 'Stock List',
    navTitle: 'Stock',
    noLongerExist: 'This product no longer exists',
  },
  userEditPage: {
    title: 'MDP: Edit User',
    heading: 'Edit User',
  },
  userCreatePage: {
    title: 'MDP: Create User',
    heading: 'Create User',
    createUser: 'Create User',
    updateUser: 'Save Changes',
    fields: {
      displayName: 'Display Name',
      firstName: 'First Name',
      company: 'Company',
      lastName: 'Last Name',
      email: 'Email',
      sites: 'Sites',
      stocks: 'Stocks via Site',
      password: 'Password',
      role: 'Role',
      distributor: 'Distributor',
      editor: 'Editor',
      permissions: 'Permissions',
      locations: 'Locations',
    },
    requirements: {
      displayName: 'Display name is required',
      firstName: 'First name is required',
      lastName: 'Last name is required',
      sites: 'At least one site is required',
      permissions: 'At least one permission is required',
      password: 'Password is required',
      email: 'Email is required',
      validEmail: 'Email must be a valid email address',
      passwordSecurity: 'Password must be at least 6 characters',
    },
    authorizedImages: 'Allowed *.jpeg, *.jpg, *.png, *.gif',
    imageMaxSize: 'max size of',
    createSuccess: 'Created user successfully',
    updateSuccess: 'Updated user successfully',
  },
  upload: {
    upload: 'Upload photo',
    update: 'Update photo',
    removeAll: 'Remove All',
    uploadFiles: 'Upload Files',
    dropOrSelect: 'Drop Or Select file',
    dropOrClick: 'Drop files here or click',
    browse: 'browse',
    thruPc: 'thorough your machine',
  },
  locationsPage: {
    title: 'MDP: Locations List',
    navTitle: 'Locations',
    heading: 'Locations List',
    name: 'Name',
    phone: 'Phone',
    address: 'Address',
    newLocation: 'New Location',
    quickEdit: 'Quick Edit',
  },
  locationsEditPage: {
    title: 'MDP: Edit a Location',
    heading: 'Edit a Location',
    currentlyEditing: 'Currently editing',
  },

  companyCreatePage: {
    title: 'MDP: Create a Company',
    heading: 'Create a Company',
    navTitle: 'Companies',
    createUser: 'Create',
    updateUser: 'Save Changes',
    currentlyEditing: 'Currently editing',
    fields: {
      name: 'Name',
      email: 'Email',
      TVA: 'TVA',
      siret: 'SIRET',
      rcs: 'RCS',
      apeLink: 'APE Code',
    },
    requirements: {
      name: 'Name is required',
      email: 'Email is required',
    },
    createSuccess: 'The company has been created',
    updateSuccess: 'The company has been updated',
  },
  companyEditPage: {
    title: 'MDP: Edit a Company',
    heading: 'Edit a Company',
    navTitle: 'Companies',
  },
  locationCreatePage: {
    title: 'MDP: Create a Location',
    heading: 'Create a Location',
    createUser: 'Create',
    updateUser: 'Save Changes',
    currentlyEditing: 'Currently editing',
    fields: {
      name: 'Name',
      phone: 'Phone',
      address: 'Address',
      city: 'City',
      zipcode: 'Zipcode',
      province: 'State',
      country: 'Country',
    },
    requirements: {
      name: 'Name is required',
      phone: 'Phone is required',
      address: 'Address is required',
      city: 'City is required',
      zipcode: 'Zipcode is required',
      province: 'State is required',
      country: 'Country is required',
    },
    error:
      'One of the following fields is already used by another location: phone, email',
    createSucess: 'The location has been created',
    updateSuccess: 'The location has been updated',
  },
  customersPage: {
    title: 'MDP: Customers List',
    navTitle: 'Customers',
    heading: 'Customers List',
    email: 'Email',
    name: 'Name',
    newCustomer: 'New Customer',
    quickEdit: 'Quick Edit',
    quickCreate: 'Quick Create',
  },
  customersEditPage: {
    title: 'MDP: Edit Customer',
    heading: 'Edit Customer',
    currentlyEditing: 'Currently editing',
  },
  customerCreatePage: {
    title: 'MDP: Create Customer',
    heading: 'Create Customer',
    createUser: 'Create',
    updateUser: 'Save Changes',
    createFailed:
      'One of the following fields is already used by another user: phone, email',
    fields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      address: 'Address',
      city: 'City',
      zipcode: 'Zipcode',
      province: 'State',
      country: 'Country',
    },
    requirements: {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      email: 'Email is required',
      validEmail: 'Email must be a valid email address',
      phone: 'Phone is required',
      address: 'Address is required',
      city: 'City is required',
      zipcode: 'Zipcode is required',
      province: 'State is required',
      country: 'Country is required',
    },
    createSuccess: 'Client has been created',
    updateSuccess: 'Client has been updated',
  },
  productsPage: {
    title: 'MDP: Products List',
    heading: 'Products List',
    navTitle: 'Products',
    inventory: 'Inventory',
    price: 'Price',
    newProduct: 'New Product',
    undefined: 'Undefined',
    stocks: {
      in_stock: 'In Stock',
      low_stock: 'Low Stock',
      out_of_stock: 'Out Of Stock',
    },
    status: {
      active: 'Active',
      archived: 'Archived',
      draft: 'Draft',
    },
    alert: {
      infoQueueTitle: 'Information',
      infoQueueDescription:
        'Products are being created or modified. If you do not see them here, please check back later by refreshing the page.',
    },
  },
  productsEditPage: {
    title: 'MDP: Edit Product',
    heading: 'Edit Product',
  },
  invoiceDialog: {
    title: 'Sending the invoice',
    details: 'Details needed to send the invoice',
    subject: 'Invoice {{name}}',
    invoiceSent: 'Invoice sent',
    send: 'Send',
    fields: {
      emailTo: 'Email to',
      emailFrom: 'Email from',
      emailSubject: 'Email subject',
    },
  },
  reserveInventoryDialog: {
    title: 'Reserve Inventory',
    date: 'Date',
  },
  productsCreatePage: {
    title: 'MDP: Create Product',
    heading: 'Create Product',
    variants: 'Variants',
    insights: 'Insights',
    deleteVariant: 'Delete variant',
    updateSucess: 'saved changes',
    createSuccess: 'Created product successfully',
    updateSuccess: 'Updated product successfully',
    createError: 'error creating product',
    price: 'Price',
    shipping: 'Shipping',
    stock: 'Stock',
    noInsights:
      'Insights will display when the product has had recent sales activity.',
    insightsDescription:
      'Sold %units units to %clients customers for €%netAmount in net sales in the last 90 days.',
    requirements: {
      title: 'Title is required',
      description: 'Description is required',
      editor: 'editor is required',
      ean: 'An EAN (barcode) is required',
      price: 'A price must be set',
      image: 'At least one image is required',
      sku: 'SKU is required',
    },
    fields: {
      weight: 'Weight',
      weight_unit: 'Unit',
      requires_shipping: 'Shipping',
      template_suffix: 'Template',
      inventoryPolicy: 'Continue selling when out of stock',
      inventoryManagement: 'Track quantity',
      description: 'Description',
      images: 'Images',
      name: 'Product Name',
      editor: 'Editor',
      productType: 'Product Type',
      status: 'Status',
      tags: 'Tags',
      title: 'Title',
      variantTitle: 'Variant Title',
      location: 'Location',
      productCode: 'EAN',
      productSku: 'Product SKU',
      regularPrice: 'Regular price',
      optionName: 'Option Name',
      optionValues: 'Option Values',
    },
    nameOfVariant: 'Variant name',
    options: 'Options',
    addVariant: 'Add Variant',
    addOption: 'Add Option',
    metafields: 'Metafields',
    createProduct: 'Create Product',
    saveChanges: 'Save changes',
    next: 'Next',
    previous: 'Previous',
    alert: {
      updateQueueTitle: 'Product update in progress',
      updateQueueMessage:
        "Once completed, this page will refresh automatically. If you've just updated it, you can leave this page without any problem.",
      createQueueTitle: 'Product creation in progress',
      createQueueMessage:
        'You can leave this page or wait here, the page will refresh automatically once the product has been created',
    },
  },
  accountPage: {
    title: 'MDP: Account Settings',
    heading: 'Account Settings',
    general: 'General',
    changePassword: 'Security',
    updateSuccess: 'Update success!',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Password',
  },
  fulfillmentDialog: {
    title: 'Fulfill Order',
    trackingNumber: 'Tracking Number',
    trackingUrl: 'Tracking Url',
    noShipping: 'No Shipping',
  },
  searchNotFound: {
    typeToSearch: 'Type to search',
    noResults: 'No results found',
    noResultsFor: 'No results found for',
    tryAgain: 'Please try again',
  },
  siteSelectionPage: {
    helmet: 'MDP: Redirect to a site',
    questionAction: 'What would you like to do?',
    goWebsite: 'See my website',
    goMdpOrder: 'Order from MDP',
  },
  createReturn: 'Create Return',
  cancelReturn: 'Cancel Return',
  pendingReturn: 'Pending Return',
  reOpenReturn: 'Reopen Return',
  returned: 'returned',
  tags: 'Tags',
  noData: 'No data',
  backward: 'Go Back',
  remainingItems: 'Remaining items',
  tracking: 'Tracking',
  cancelFulfillment: 'Cancel Fulfillment',
  fulfilled: 'Fulfilled',
  fulfill: 'Fulfill',
  cancel: 'Cancel',
  add: 'Add',
  remove: 'Remove',
  paid: 'Paid',
  address: 'Address',
  payment: 'Payment',
  unpaid: 'Unpaid',
  value: 'Value',
  logout: 'Log out',
  unableLogout: 'Unable to logout!',
  settings: 'Settings',
  sureAction: 'Are you sure want to delete?',
  sureDelete: 'Are you sure you want to delete',
  sureDelete2: 'items?',
  edit: 'Edit',
  selected: 'selected',
  delete: 'Delete',
  phoneNumber: 'Phone Number',
  dense: 'dense',
  startDate: 'Start date',
  endDate: 'End date',
  others: 'Others',
  dashboardName: 'Dashboard',
  location: 'Location',
  locations: 'Locations',
  other: 'other',
  all: 'All',
  total: 'Total',
  visit: 'visit',
  taxes: 'Taxes',
  discount: 'Discount',
  viewAll: 'View All',
  view: 'View',
  createdAt: 'Created At',
  customer: 'Customer',
  company: 'Company',
  quickEdit: 'Quick Edit',
  status: 'Status',
  amount: 'Amount',
  order: 'Order',
  orders: 'Orders',
  quantity: 'Quantity',
  app: 'app',
  user: 'User',
  list: 'List',
  shop: 'shop',
  blog: 'blog',
  post: 'post',
  mail: 'mail',
  chat: 'chat',
  cards: 'cards',
  posts: 'posts',
  create: 'create',
  kanban: 'kanban',
  general: 'general',
  banking: 'banking',
  booking: 'booking',
  profile: 'profile',
  account: 'account',
  product: 'Product',
  products: 'products',
  invoice: 'invoice',
  details: 'Details',
  checkout: 'checkout',
  calendar: 'calendar',
  analytics: 'analytics',
  ecommerce: 'E-Commerce',
  management: 'management',
  menu_level: 'menu level',
  stock: 'Stock',
  home: 'home',
  work: 'Current work',
  application: 'Application',
  menu_level_2a: 'menu level 2a',
  menu_level_2b: 'menu level 2b',
  menu_level_3a: 'menu level 3a',
  menu_level_3b: 'menu level 3b',
  menu_level_4a: 'menu level 4a',
  menu_level_4b: 'menu level 4b',
  item_disabled: 'item disabled',
  item_label: 'item label',
  item_caption: 'item caption',
  item_external_link: 'item external link',
  description: 'description',
  other_cases: 'other cases',
  update: 'Update',
  item_by_roles: 'item by roles',
  only_admin_can_see_this_item: 'Only admin can see this item',
  goToHome: 'Go to home',
  welcome_to_MDP: 'Welcome to the MDP solution!',
  login: 'Login',
  email_adress: 'Email adress',
  password: 'Password',

  waiting_for_supplier: 'Waiting for supplier',
  in_preparation: 'In preparation',
  arrival_in_progress: 'Arrival in progress',
  waiting_for_regrouping: 'Waiting for regrouping',
  pre_order: 'Pre-order',
  to_ship: 'To ship',

  websites: 'Websites',
  myWebsite: 'My website',
  mdpOrder: 'MDP Order',

  refound: 'Refound',

  cancelDialog: {
    refund: 'Cancellation',
    title: 'Order Cancellation',
    amount: 'Amount',
    cancelSent: 'The order has been refunded',
    save: 'Cancel the order',
    amountExceeded: 'You cannot refund more than the amount of the order.',
    amountRequired: 'You must enter the refund amount (it can be 0).',
  },

  axiosError: 'An error has occurred',

  supportListPage: {
    navTitle: 'Support',
  },

  upComingAppsPage: {
    title: 'MDP: Application',
    appUpCommingList: 'Up Comming Applications List...',
    appList: 'Available Applications List',
    description:
      'Discover the list of applications available on the MDP platform.',
    web: {
      title: 'Website',
      navTitle: 'Website',
      description: `MDP Website changes the way people think about website design. With its intuitive and user-friendly interface, you can easily create, manage, and customize your website.
        Your custom website or application, in showcase or e-commerce format (online sales, click & collect, local delivery), white-labeled with optimized local SEO for your business.`,
      liveChatApp: {
        title: 'Live Chat',
        description: 'Chat directly with your online visitors',
      },
      blogApp: {
        title: 'Blog',
        description: 'Publish articles, announcements, news and create sales',
      },
      forumApp: {
        title: 'Forum',
        description: 'Manage a forum with FAQs and Q&As',
      },
    },
    sale: {
      title: 'Sales',
      navTitle: 'Sales',
      description:
        'MDP Sales is the true customer-centric tool for all your business needs. Track your leads, get accurate forecasts, and focus on what matters: closing opportunities.',
      subscription: {
        title: 'Subscriptions',
        description: 'Recurring bills and renewals',
      },
      rental: {
        title: 'Rental',
        description: 'Manage contracts, deliveries, and returns',
      },
      CRM: {
        title: 'CRM',
        description: 'Track leads and seize opportunities',
      },
      sales: {
        title: 'Sales',
        description: 'From quote to invoice',
      },
      POS: {
        title: 'Point of Sale',
        description: 'POS interface for stores',
      },
    },
    finance: {
      title: 'Finance',
      navTitle: 'Finance',
      description:
        'MDP Finance is a modern management software. Experience work differently and no longer suffer from the frustrations related to slow interfaces, cluttered email inboxes, and data pile-up.',
      accounting: {
        title: 'Accounting',
        description: 'Manage financial and analytical accounting',
      },
      document: {
        title: 'Documents',
        description: 'Document management',
      },
      spreadsheet: {
        title: 'Spreadsheet',
        description: 'Spreadsheet document',
      },
      sign: {
        title: 'Signature',
        description: 'Sign documents online',
      },
      facturing: {
        title: 'Invoicing',
        description: 'Invoices & payments',
      },
      expenses: {
        title: 'Expense Reports',
        description: "Manage your employees' expenses",
      },
    },
    inventory: {
      title: 'Inventory & Manufacturing',
      navTitle: 'Inventory',
      description:
        'Reduce stockouts, speed up operations, optimize routes, and get real-time visibility with MDP’s warehouse management application.',
      manufacturing: {
        title: 'Manufacturing',
        description: 'Manufacturing orders & bill of materials',
      },
      PLM: {
        title: 'PLM',
        description: 'Product lifecycle management',
      },
      quality: {
        title: 'Quality',
        description: 'Control the quality of your products',
      },
      stock: {
        title: 'Inventory',
        description: 'Manage your stocks and logistics activities',
      },
      purchase: {
        title: 'Purchasing',
        description: 'Purchase orders, tenders, and contracts',
      },
      maintenance: {
        title: 'Maintenance',
        description: 'Track equipment and manage requests',
      },
    },
    hr: {
      title: 'Human Resources',
      navTitle: 'HR',
      description:
        'MDP’s Employee application is much more than just a personnel register. It’s the central point of a complete suite of HR functionalities.',
      employee: {
        title: 'Employees',
        description: "Centralize your employees' information",
      },
      recruitment: {
        title: 'Recruitment',
        description: 'Control your recruitment pipeline',
      },
      leaves: {
        title: 'Leaves',
        description: 'Allocate leave days and track requests',
      },
    },
    marketing: {
      title: 'Marketing',
      navTitle: 'Marketing',
      description:
        'Discover unlimited marketing possibilities with fully automated, easy-to-use, and autonomous workflows.',
      email: {
        title: 'Email & SMS',
        description: 'Create your emails and SMS with integrated tracking',
      },
      automation: {
        title: 'Marketing Automation',
        description: 'Design your campaigns',
      },
      social: {
        title: 'Social',
        description: 'Manage your social networks and website visitors',
      },
      event: {
        title: 'Event',
        description: 'Publish your events',
      },
      poll: {
        title: 'Survey',
        description: 'Send your surveys or share them live',
      },
    },
    services: {
      title: 'Services',
      navTitle: 'Services',
      description:
        'MDP service platform simplifies your team’s daily life, so they can better assist your customers. Say goodbye to complexity and enter a new era of transparent, efficient, and customer-centered support.',
      timeSheet: {
        title: 'Time Sheet',
        description: 'Track the time your employees spend on their tasks',
      },
      onSite: {
        title: 'On-site Services',
        description:
          'Schedule and track on-site operations, time, and materials',
      },
      support: {
        title: 'Support',
        description: 'Track, prioritize, and solve customer issues',
      },
      schedule: {
        title: 'Scheduling',
        description: "Manage your employees' schedules",
      },
      rendezvous: {
        title: 'Appointments',
        description: 'Schedule meetings in your calendar',
      },
      project: {
        title: 'Project',
        description: 'Organize and plan your projects',
      },
    },
    productivity: {
      title: 'Productivity',
      navTitle: 'Productivity',
      description:
        'MDP Productivity allows your teams to communicate and collaborate with ease.',
      validation: {
        title: 'Validation',
        description: 'Manage and validate approval requests',
      },
      import: {
        title: 'Import',
        description: 'Import your catalogs and create them in a flash',
      },
      chat: {
        title: 'Chat',
        description: 'Chat, messaging gateway, and private channels',
      },
    },
    footer: {
      line1: 'Need an app?',
      line2: 'Our development team is here to create it for you!',
    },
  },

  upComingApps: 'Upcoming applications',

  publication: 'Publication',
  salesMethod: 'Sales method',
  close: 'Close',
  apeCode: 'APE code',
  companies: 'Company(ies)',
  publicationExceptions: 'Banned sales channels',
  method: 'Method',

  permissionsTypes: {
    list: 'View',
    edit: 'Edit',
    create: 'Create',
    delete: 'Delete',

    product: 'Product',
    customer: 'Customer',
    location: 'Location',
    company: 'Company',
    stock: 'Stock',
    order: 'Order',
    user: 'User',
    publication: 'Publication',
    apeLink: 'APE Link',
    site: 'Site',
    dashboard: 'Dashboard',
  },

  noDataError: `No data were found...`,
  reloadPage: 'Reload page',
};

export default en;
